import React, { useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Auth from "pages/Auth";
const App = React.lazy(() => import("./App"));

const AuthMiddleware = () => {
  const [auth, setAuth] = useState(false);

  if (!auth)
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            element={
              <Auth
                authorize={() => {
                  setAuth(true);
                }}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    );
  else return <App />;
};

export default AuthMiddleware;
