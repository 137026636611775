import React from "react";
import ReactDOM from "react-dom/client";
import "assets/index.css";
import AuthMiddleware from "./AuthMiddleware";
// import App from "./App";
import reportWebVitals from "./utils/reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<AuthMiddleware />);
// Disabled strict mode to maintain dnd in 18 version

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
