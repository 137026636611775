import { useSearchParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import CryptoJS from "crypto-js";

// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import bcrypt from "bcrypt";
// import { Block } from "components/Block";

const Auth = ({ authorize }) => {
  const navigate = useNavigate();
  const [searchP, setSearchP] = useSearchParams();
  const [pass, setPass] = useState(searchP.get("pass"));
  const [checked, setChecked] = useState(false);
  const authToken = process.env.REACT_APP_PASSPHRASE_HASHED;

  useEffect(() => {
    const hash = !!pass
      ? CryptoJS.HmacMD5(pass, "not-secure").toString(CryptoJS.enc.Hex)
      : localStorage.getItem("SECRET_TOKEN");

    if (hash === authToken) {
      localStorage.setItem("SECRET_TOKEN", hash);
      authorize();
      searchP.delete("pass")
      setSearchP(searchP);
    }
    else if (!checked) {
      setChecked(true);
    }
    return () => {
    };
  }, [pass, authToken]);

  return (
    <div
      className="centered"
      style={{
        textAlign: "center",
        display: !!checked ? "block" : "none",
      }}
    >
      <label htmlFor="password">Для доступа введите пароль:</label>
      <br />
      <br />
      <input
        type="password"
        name="password"
        id="password"
        onChange={(e) => {
          setPass(e.target.value);
        }}
      />
    </div>
  );
};

export default Auth;
